<template>
	<label class="inputBoxWrap">
		<select
			v-model.trim="value"
			@focus="eventFocus"
			@blur="eventBlur"
			:name="refs"
			:placeholder="item.label"
			:class="{ errFieldBorder: showErrorMessage && status !== 0 }"
		>
			<option value="" disabled selected>{{ item.label }}</option>
			<option
				:key="index"
				:value="option.value"
				v-for="(option, index) in inputFilter"
			>
				{{ option.name }}
			</option>
		</select>
		<label>{{ item.label }}</label>
		<p class="errorMsg" v-if="showErrorMessage">{{ errorMsg }}</p>
	</label>
</template>
<script>
import DataForInput from '@/store/repeatLoan/longFormOptions';
import { mapState } from 'vuex';
import tracing from '@/common/tracing';

export default {
	name: 'formSelect',

	data: function () {
		return {
			item: DataForInput[this.refs]
		};
	},

	props: {
		refs: {
			type: String,
			default: 'null'
		},
		type: {
			type: String,
			default: 'form'
		},
		firstValueSetActive: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		...mapState({
			valueVuex: (state) => state.repeatLoan.sendData,
			statusVuex: (state) => state.repeatLoan.workData,
			refVuex: (state) => state.repeatLoan.refs
		}),
		inputFilter() {
			const out = this.refVuex[this.refs] ?? [];
			// todo разобраться что должна выводить эта сортировка - возможно неоднозначное поведение
			// так же делать parseInt в каждом сравнеии не экономично
			return out.sort((a, b) => {
				return a.sort !== undefined && b.sort !== undefined
					? parseInt(a.sort, 10) - parseInt(b.sort, 10)
					: 0;
			});
		},
		status() {
			return this.statusVuex[this.refs].code;
		},
		errorMsg() {
			return this.statusVuex[this.refs].msg;
		},
		showErrorMessage() {
			return this.statusVuex[this.refs].ShowError;
		},
		value: {
			get() {
				return this.valueVuex[this.refs];
			},
			set(value) {
				this.$store.dispatch('repeatLoan/saveSendData', {
					target: this.refs,
					value: value
				});
			}
		}
	},

	mounted() {
		if (this.firstValueSetActive) {
			this.value = this.inputFilter[0].value;
		}
	},

	methods: {
		eventFocus() {
			this.$store.dispatch('repeatLoan/errorShow', {
				target: this.refs,
				value: false
			});
			tracing.traceInput({
				action: 164,
				refs: this.refs,
				value: this.valueVuex[this.refs]
			});
		},
		eventBlur() {}
	}
};
</script>